<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
	export default {
		name: 'app',
		data() {
		    return {
		      windowWidth: window.innerWidth,
		      mobileBreakpoint: 540 // 定义移动端和桌面端的分界点
		    };
		},
		computed: {
		    isMobileLayout() {
				return this.windowWidth < this.mobileBreakpoint
		    }
		},
		created() {
			this.$store.dispatch('games/initializeData')
			this.$nextTick(() => {
				window.addEventListener('resize', this.handleResize)
				this.handleResize(); // 初始化时调用一次
			})
		},
		methods: {
		    handleResize() {
		      this.windowWidth = window.innerWidth
		    }
		},
		// 依赖注入
		provide() {
			return {
				app: this
			}
		}
	}
</script>
