import Vue from 'vue';
import Vuex from 'vuex';
// 引入模块
import games from './modules/games/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		games
	}
})